// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import rates from '@src/views/apps/quotes/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import specialCustomer from '@src/views/apps/reports/special-reports/store'
import oceanSchedule from '@src/views/apps/freight-forward-request/store-ocean-schedule'
import freightForward from '@src/views/apps/freight-forward-request/store'
import inboundRequest from '@src/views/apps/transportation/inbound-requests/store'
import movement from '@src/views/apps/terminal-cpt/store'
import datasummreport from '@src/views/apps/reports/store'
import shipments from '@src/views/apps/freight-forward-request/store/shipments'
import stuffing from '@src/views/apps/reports/store/stuffing'
import vgm from '@src/views/apps/reports/store/vgm'
import carrecon from '@src/views/apps/reports/store/carrecon'
import oceanBookings from '@src/views/apps/freight-forward-request/store/oceanBookings'
import ffReports from '@src/views/apps/freight-forward-request/store/ffReports'
import ffdashboard from '@src/views/dashboard/ffdashboard/store'
import singleSignOn from '@src/views/apps/admin/store/singleSignOn'
import crmLeads from '@src/views/apps/crm/leads/store'
import crmDeals from '@src/views/apps/crm/deals/store'
import crmContacts from '@src/views/apps/crm/contacts/store'
import crmCompany from '@src/views/apps/company/store'
import hrmTimeSheets from '@src/views/apps/hrm/time-sheet/store'
import hrmTSReport from '@src/views/apps/hrm/time-sheet/store/report'
import clockInOut from '@src/views/pages/daily-planning/store'
import dailyPlanning from '@src/views/pages/daily-planning/store/dailyPlanning'
import resourcePlanning from '@src/views/pages/resource-planning/store'
import accountSettings from '@src/views/pages/account-settings/store'
import bookTimeOff from '@src/views/pages/book-time-off/store'
import holidayCalendar from '@src/views/apps/hrm/holiday-calendar/store'
import userSkills from '@src/views/apps/hrm/skills/store'
import cardTapAccess from '@src/views/clock/store'
import vesselVoyage from '@src/views/apps/vesselvoyage/schedule/store'
import yardMoveReducer from '@src/views/apps/transportation/yardMovements/store/reducer'
import { combineReducers } from "redux"
import vesselTracker from '@src/views/apps/vesselvoyage/store/vesselTracker'
import terminalReducer from '@src/views/apps/terminal-cpt/newTerminalForm/store/reducer'
import hrConfigReducer from '@src/views/apps/configuration/store'
import vesselList from '@src/views/apps/vesselvoyage/vessel/store'
import terminalList from '@src/views/apps/vesselvoyage/terminal/store'
import vesselMind from '@src/views/apps/vesselvoyage/vesselMind/store'
import oceanBooking from '@src/views/apps/ocean-booking/store'
import landBooking from '@src/views/apps/land-booking/store'
import blockDriver from '@src/views/apps/transportation/driver/block-driver/store'
import hrmPieceWork from '@src/views/apps/hrm/time-sheet/store/pieceWork'
import transloadReducer from '@src/views/apps/transload-order/store'
import inventoryReducer from '@src/views/apps/inventory/store'
import dunnage from '@src/views/apps/inventory/dunnage-management/store'
import toDoTranportation from '@src/views/apps/transportation/ToDoList/store'
import customerDashboard from '@src/views/dashboard/customerDashboard/store'
import ediRecords from '@src/views/apps/terminal-cpt/EdiRecords/store'
import driver from '@src/views/apps/transportation/driver/store'
import outbound from '@src/views/apps/transportation/outbound-requests/store'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  rates,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  specialCustomer,
  oceanSchedule,
  freightForward,
  inboundRequest,
  movement,
  datasummreport,
  shipments,
  vesselTracker,
  stuffing,
  vgm,
  carrecon,
  oceanBookings,
  ffReports,
  ffdashboard,
  customerDashboard,
  singleSignOn,
  crmLeads,
  crmDeals,
  crmContacts,
  crmCompany,
  hrmTimeSheets,
  hrmTSReport,
  clockInOut,
  dailyPlanning,
  resourcePlanning,
  accountSettings,
  bookTimeOff,
  holidayCalendar,
  userSkills,
  cardTapAccess,
  vesselVoyage,
  yardMoveReducer,
  terminalReducer,
  hrConfigReducer,
  vesselList,
  terminalList,
  vesselMind,
  oceanBooking,
  landBooking,
  hrmPieceWork,
  transloadReducer,
  toDoTranportation,
  dunnage,
  ediRecords,
  blockDriver,
  inventoryReducer,
  driver,
  outbound
})

export default rootReducer