// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'

import { urlDashboardService, urlReportingService } from '@src/endpoints'
import { getUriParams } from '@src/utility/Utils'
import { Alert } from 'bootstrap'


export const getAllData = createAsyncThunk('ffdashboard/GetTotalsFFDashboard', async params => {
  const FFDashboardDTO = {
    fromDate : params.fromDate,
    toDate : params.toDate
  }
  const response = await axios.post(`${urlDashboardService}/GetTotalsFFDashboard`, FFDashboardDTO)
  return {
    data: response.data.data
  }
})

export const getVesselData = createAsyncThunk('ffdashboard/GetFFDashboardVessels', async params => {
  const uri =  getUriParams(params)
  const response = await axios.post(`${urlDashboardService}/GetFFDashboardVessels?${uri}`, {})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount
  }
})

export const getVesselTrackerDownload = createAsyncThunk('ffdashboard/GetVesselTrackerDownload', async params => {
  const uri =  getUriParams(params)
  const response = await axios.post(`${urlDashboardService}/GetVesselTrackerDownload?${uri}`)
  return {
    loaded: true,
    params,
    download: response.data.data,
    totalPages: response.data.totalCount
  }
})

// ff dashboard alert
export const getFFDashboardAlert = createAsyncThunk('ffdashboard/GetFFDashboardAlert', async params => {
  const response = await axios.get(`${urlReportingService}/GetShipmentProgressAlert`, {})
  return {
    params,
    dashBoardalert: response.data
  }
})

export const ffDashboardSlice = createSlice({
  name: 'ffdashboard',
  initialState: {
    loaded: false,
    allData: {},
    data: {},
    download: {},
    params: {},
    dashBoardalert: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getVesselData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVesselTrackerDownload.pending, (state) => {
        state.loaded = false
      })
      .addCase(getVesselTrackerDownload.fulfilled, (state, action) => {
        state.loaded = true
        state.download = action.payload.download
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getFFDashboardAlert.fulfilled, (state, action) => {
        state.dashBoardalert = action.payload.dashBoardalert
      })
  }
})

export default ffDashboardSlice.reducer
