import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' // 👈️ Redux Imports
import axios from 'axios'// 👈️ Axios Imports
import { urlReportingService } from '@src/endpoints' // 👈️ API Microservice EndPoint
import { getUriParams } from '@src/utility/Utils' // 👈️ Utility

export const getData = createAsyncThunk('stuffing/getData', async params => {
  const uri = getUriParams(params)
  const ReportFilter = {
    fromDate: params.fromDate === '' ? null : params.fromDate,
    toDate: params.toDate === '' ? null : params.toDate,
    transloadOrderNumber: params.orderNumber === '' ? null : params.orderNumber,
    oceanBookingNumber: params.bookingNumber === '' ? null : params.bookingNumber,
    isOrganic: params.isOrganic || null,
    customerName: params.customerName === '' ? null : params.customerName,
    shippingLineCode: params.shippingLineCode === '' ? null : params.shippingLineCode,
    status: null //params.status?.value
  }
  const response = await axios.post(`${urlReportingService}/GetAllTransloadBookingReportList?${uri}`, ReportFilter)
  const responseData = await response.data.data
  console.log('stuffing', response)
  // const newData = []
  // await responseData.map(async(record) => {
  //   const oid = record.transloadOrderId
  //   const bid = record.transloadBookingAsnId
  //   const carReconResponse = await axios.get(`${urlReportService}/GetCarReconReport/${oid}/${bid}`)
  //   console.log("reconresponse", carReconResponse)
  //   const carReconData = carReconResponse.data.data
  //   console.log(carReconData)
  //   const newRecord = {...record, carReconData }
  //   console.log(newRecord)
  //   newData.push(newRecord)
  // })

  return {
    params,
    data: responseData,
    filter: ReportFilter,
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 0,
    loaded: true
  }
})

export const getStuffingReport = createAsyncThunk('stuffing/getStuffingReport', async (param) => {
  console.log('param', param)
  const response = await axios.get(`${urlReportingService}/GetTransloadBookingReport/${param.oid}/${param.bid}`)
  console.log('response stuff', response)
  return {
    stuffingReport: response.data.data,
    vloaded: true
  }
})
//get nova transload report
export const getNovaTransloadReport = createAsyncThunk('ReportingService/GetNovaTransloadReport', async (param) => {
  const response = await axios.get(`${urlReportingService}/GetNovaTransloadBookingReport/${param.PONumber}`)
  console.log("nova transloading report", response)
  return {
    novaTransloadReport: response.data.data
  }
})

//GET SPECIAL CUSTOMERS BY CUSTOMER CODE
export const getSpecialCustomersByCustomerCode = createAsyncThunk('specialCustomer/getSpecialCustomersByCustomerCode', async customerCode => {
  const response = await axios.get(`${urlReportingService}/GetSpecialReport/${customerCode}`)
  console.log("ctc intact report", response)
  return {
    ctcIntactReport: response.data.data
     }
})

export const updateStuffingReport = async (data) => {
  const response = await axios.post(`${urlReportingService}/AddOrUpdateTransloadBookingReport`, data)
  if (response.data.success) {
    return { success: true, data: response.data.data }
  } else {
    return { success: false, message: response.data.messages }
  }
}

export const stuffingSlice = createSlice({
  name: 'stuffing',
  initialState: {
    loaded: false,
    vloaded: false,
    verror: false,
    data: [],
    total: 1,
    params: {},
    stuffingReport: null,
    novaTransloadReport: null,
    ctcIntactReport: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loaded = false
        state.vloaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filter
      })
      .addCase(getData.rejected, (state) => {
        state.loaded = true
        state.data = []
        state.total = 0
      })
      .addCase(getStuffingReport.fulfilled, (state, action) => {
        state.vloaded = action.payload.vloaded
        state.stuffingReport = action.payload.stuffingReport
      })
      .addCase(getStuffingReport.rejected, (state) => {
        state.vloaded = true
        state.verror = true
      })
      .addCase(getNovaTransloadReport.fulfilled, (state, action) => {
        state.vloaded = true
        state.novaTransloadReport = action.payload.novaTransloadReport
      })
      .addCase(getSpecialCustomersByCustomerCode.fulfilled, (state, action) => {
        state.vloaded = true
        state.ctcIntactReport = action.payload.ctcIntactReport
      })
      
  }
})

export default stuffingSlice.reducer

stuffingSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'Stuffing Reports - Store {Get Data from Microservices}',
  filename: 'stuffing',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/reports/store/stuffing.js',
  prodUrl: ''
}