// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const Chat = lazy(() => import('../../views/apps/chat'))
const Todo = lazy(() => import('../../views/apps/todo'))
const Email = lazy(() => import('../../views/apps/email'))
const Calendar = lazy(() => import('../../views/apps/calendar'))

const InvoicingTInvList = lazy(() => import('../../views/apps/invoice/transloading/invoiceList')) // Transloading Invoice List
const InvoicingTBkgList = lazy(() => import('../../views/apps/invoice/transloading/bookingList')) // Transloading Booking List
const InvoicingTInvPreview = lazy(() => import('../../views/apps/invoice/transloading/preview')) // Transloading Invoice Preview
const InvoicingTInvEdit = lazy(() => import('../../views/apps/invoice/transloading/edit')) // Transloading Invoice Edit
const InvoicingChrgSumm = lazy(() => import('../../views/apps/invoice/transloading/chargeSummary')) // Charge Summary
const InvoicingChrgSummEdit = lazy(() => import('../../views/apps/invoice/transloading/chargeSummary/edit')) // Charge Summary
const InvoicingChrgSummPreview = lazy(() => import('../../views/apps/invoice/transloading/chargeSummary/preview')) // Charge Summary

const QuoteAdd = lazy(() => import('../../views/apps/quotes/add'))
const QuoteEdit = lazy(() => import('../../views/apps/quotes/edit'))
// const QuoteList = lazy(() => import('../../views/apps/quotes/list'))
const QuotePreview = lazy(() => import('../../views/apps/quotes/view'))

const Rates = lazy(() => import('../../views/apps/quotes/rates'))

const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'))
const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'))
const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'))
const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'))

const UserList = lazy(() => import('../../views/apps/user/list'))
const UserView = lazy(() => import('../../views/apps/user/view'))
const DepartmentList = lazy(() => import('../../views/apps/department'))
const TerminalIngateList = lazy(() => import('../../views/apps/terminal-cpt/ingate-list'))
const BlockList = lazy(() => import('../../views/apps/terminal-cpt/block-list'))
// const TerminalIngate = lazy(() => import('../../views/apps/terminal-cpt/newTerminalForm'))
const ViewTerminalDetail = lazy(() => import('../../views/apps/terminal-cpt/ViewMovement'))
const EquipmentInventoryList = lazy(() => import('../../views/apps/terminal-cpt/Equipment/Inventory/List'))
const EquipmentStorageList = lazy(() => import('../../views/apps/terminal-cpt/Equipment/Storage/List'))
const EDIList = lazy(() => import('../../views/apps/terminal-cpt/EdiRecords/List'))

const ReportDataSumm = lazy(() => import('../../views/apps/reports/datasumm'))
const ReportStuffing = lazy(() => import('../../views/apps/reports/stuffing'))
const ShipmentProgress = lazy(() => import('../../views/apps/reports/shipment-progress'))
const SpecialReports = lazy(() => import('../../views/apps/reports/special-reports'))
const SpecialReportsView = lazy(() => import('../../views/apps/reports/special-reports/view/index'))
const InboundTracing = lazy(() => import('../../views/apps/reports/inbound-tracing'))
const CleaningLog = lazy(() => import('../../views/apps/reports/cleaning-log'))
const ReportStuffingPreview = lazy(() => import('../../views/apps/reports/stuffing/preview'))
const ReportVGMPreview = lazy(() => import('../../views/apps/reports/vgm/preview'))
const ReportFFReportPreview = lazy(() => import('../../views/apps/reports/ff-booking/preview'))
const CleanlinessCertificate = lazy(() => import('../../views/apps/reports/cleanliness'))
const CleanlinessPreview = lazy(() => import('../../views/apps/reports/cleanliness/preview'))
const WeightPreview = lazy(() => import('../../views/apps/reports/weight/preview'))
const OriginPreview = lazy(() => import('../../views/apps/reports/origin/preview'))
const WoodPacking = lazy(() => import('../../views/apps/reports/wood-packing/preview'))
const NonWoodPacking = lazy(() => import('../../views/apps/reports/non-wood-packing/preview'))
const ReportRailcarPreview = lazy(() => import('../../views/apps/reports/carrecon/preview'))
const StorageOut = lazy(() => import('../../views/apps/reports/storage-out'))
const DemurrageIMDL = lazy(() => import('../../views/apps/reports/demurrage_IMDL'))
const NovaTransloadingReport = lazy(() => import('../../views/apps/reports/nova-transloading'))
const CtcIntactReport = lazy(() => import('../../views/apps/reports/ctc-intactReport'))
const FreightForwardList = lazy(() => import('../../views/apps/freight-forward-request/list'))
const FreightForwardView = lazy(() => import('../../views/apps/freight-forward-request/view'))
const OceanSchedule = lazy(() => import('../../views/apps/freight-forward-request/ocean-schedule'))
const FreightForwardPrint = lazy(() => import('../../views/apps/freight-forward-request/print/BookingConfirmation'))
const FreightForwardDepartureNoticePrint = lazy(() => import('../../views/apps/freight-forward-request/print/DepartureNotice'))
const FreightForwardPreview = lazy(() => import('../../views/apps/freight-forward-request/booking-confirmation-preview'))
const FreightForwardDepartureNoticePreview = lazy(() => import('../../views/apps/freight-forward-request/departure-notice-preview'))
const ShipmentsList = lazy(() => import('../../views/apps/freight-forward-request/shipments'))

const InboundRequestList = lazy(() => import('../../views/apps/transportation/inbound-requests/list'))
const InboundRequestView = lazy(() => import('../../views/apps/transportation/inbound-requests/view/request'))

const OutboundRequestList = lazy(() => import('../../views/apps/transportation/outbound-requests/list'))
const Dispatch = lazy(() => import('../../views/apps/transportation/dispatch/List'))
const YardMove = lazy(() => import('../../views/apps/transportation/yardMovements/List'))
const DriverList = lazy(() => import('../../views/apps/transportation/driver/list'))
const DriverView = lazy(() => import('../../views/apps/transportation/driver/view'))
const DriverBlockList = lazy(() => import('../../views/apps/transportation/driver/block-driver/list'))
const TodoListTransportation = lazy(() => import('../../views/apps/transportation/ToDoList/List'))

const Roles = lazy(() => import('../../views/apps/roles-permissions/roles'))
const Permissions = lazy(() => import('../../views/apps/roles-permissions/permissions'))

const SingleSignOnList = lazy(() => import('../../views/apps/admin/single-sign-on'))

const CRMLeadsList = lazy(() => import('../../views/apps/crm/leads/list'))
const CRMLeadsView = lazy(() => import('../../views/apps/crm/leads/view'))
const CRMDealsList = lazy(() => import('../../views/apps/crm/deals/list'))
const CRMDealsView = lazy(() => import('../../views/apps/crm/deals/view'))
const CRMDealsKanban = lazy(() => import('../../views/apps/crm/deals/list/Kanban'))
const CRMContactsList = lazy(() => import('../../views/apps/crm/contacts/list'))
const CRMContactView = lazy(() => import('../../views/apps/crm/contacts/view'))
const CRMCompanyList = lazy(() => import('../../views/apps/company/list'))
const CRMCompanyView = lazy(() => import('../../views/apps/company/view'))
const CustomerPortalList = lazy(() => import('../../views/apps/crm/customer-portal/list'))
const CustomerPortalView = lazy(() => import('../../views/apps/crm/customer-portal/view'))
const HRMTimeSheetList = lazy(() => import('../../views/apps/hrm/time-sheet/list'))
const HRMTimeSheetReportDateRangeList = lazy(() => import('../../views/apps/hrm/time-sheet/report/by-date-range/'))
const HRMTimeSheetReportPrint = lazy(() => import('../../views/apps/hrm/time-sheet/print/'))
const LeaveBoard = lazy(() => import('../../views/apps/hrm/employees/leave-board/'))
const HolidayCalendar = lazy(() => import('../../views/apps/hrm/holiday-calendar'))
const UserSkills = lazy(() => import('../../views/apps/hrm/skills/list'))

const HRMPayPeriodList = lazy(() => import('../../views/apps/hrm/time-sheet/piece-work/list'))
const HRMPieceWorkReportDateRangeList = lazy(() => import('../../views/apps/hrm/time-sheet/piece-work/Report'))
const HRMPieceWorkReportPrint = lazy(() => import('../../views/apps/hrm/time-sheet/piece-work/print/'))

const VesselVoyage = lazy(() => import('../../views/apps/vesselvoyage/schedule/list'))
const VesselMind = lazy(() => import('../../views/apps/vesselvoyage/vesselMind/list'))
const VesselTracker = lazy(() => import('../../views/apps/vesselvoyage/tracker'))
const VesselList = lazy(() => import('../../views/apps/vesselvoyage/vessel/list'))
const TerminalList = lazy(() => import('../../views/apps/vesselvoyage/terminal/list'))

const HrConfiguration = lazy(() => import('../../views/apps/configuration/hr'))
const CompanyConfiguration = lazy(() => import('../../views/apps/configuration/company/index'))
const QualityAssuranceConfiguration = lazy(() => import('../../views/apps/configuration/quality-assurance/index'))
const CommodityConfiguration = lazy(() => import('../../views/apps/configuration/commodity/index'))
const PackagingConfiguration = lazy(() => import('../../views/apps/configuration/packaging/index'))
const TransloadOrderConfiguration = lazy(() => import('../../views/apps/configuration/transload-order/index'))
const QualityIssueConfiguration = lazy(() => import('../../views/apps/configuration/quality-issue/index'))

const OceanBookingList = lazy(() => import('../../views/apps/ocean-booking/list'))
const OceanBookingView = lazy(() => import('../../views/apps/ocean-booking/view/index'))

const LandBookingList = lazy(() => import('../../views/apps/land-booking/list'))
const LandBookingView = lazy(() => import('../../views/apps/land-booking/view/index'))

const TransloadOrder = lazy(() => import('../../views/apps/transload-order/index'))
const LoadingInstruction = lazy(() => import('../../views/apps/transload-order/loading-instruction/index'))
const Instruction = lazy(() => import('../../views/apps/transload-order/instruction/index'))
const TemporaryStorageTransloadOrder = lazy(() => import('../../views/apps/transload-order/Temporary_storage/index'))
const SpecialTransloadMain = lazy(() => import('../../views/apps/transload-order/special_transload/index'))
const ViewSpecialTransload = lazy(() => import('../../views/apps/transload-order/special_transload/View_special_transaction/index'))

const PackagingManagement = lazy(() => import('../../views/apps/inventory/packaging-management/index'))
const PackagingData = lazy(() => import('../../views/apps/inventory/packaging-management/PackagingData'))
const DunnageManagement = lazy(() => import('../../views/apps/inventory/dunnage-management/index'))
// const DunnageData = lazy(() => import('../../views/apps/inventory/dunnage-management/DunnageData'))
const MaterialInventory = lazy(() => import('../../views/apps/inventory/material-inventory'))
const CargoInventory = lazy(() => import('../../views/apps/inventory/cargo-inventory'))

const AppRoutes = [
  {
    element: <Email />,
    path: '/apps/email',
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <Email />,
    path: '/apps/email/:folder',
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <Email />,
    path: '/apps/email/label/:label',
    meta: {
      appLayout: true,
      className: 'email-application'
    }
  },
  {
    element: <Email />,
    path: '/apps/email/:filter'
  },
  {
    path: '/apps/chat',
    element: <Chat />,
    meta: {
      appLayout: true,
      className: 'chat-application'
    }
  },
  {
    element: <Todo />,
    path: '/apps/todo',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <Todo />,
    path: '/apps/todo/:filter',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <Todo />,
    path: '/apps/todo/tag/:tag',
    meta: {
      appLayout: true,
      className: 'todo-application'
    }
  },
  {
    element: <Calendar />,
    path: '/apps/calendar'
  },
  // {
  //   element: <InvoiceList />,
  //   path: '/apps/invoice/list'
  // },
  // {
  //   element: <InvoicePreview />,
  //   path: '/apps/invoice/preview/:id'
  // },
  // {
  //   path: '/apps/invoice/preview',
  //   element: <Navigate to='/apps/invoice/preview/4987' />
  // },
  // {
  //   element: <InvoiceEdit />,
  //   path: '/apps/invoice/edit/:id'
  // },
  // {
  //   path: '/apps/invoice/edit',
  //   element: <Navigate to='/apps/invoice/edit/4987' />
  // },
  // {
  //   element: <InvoiceAdd />,
  //   path: '/apps/invoice/add'
  // },
  {
    element: <QuoteAdd />,
    path: '/apps/quote/add'
  },
  // {
  //   element: <QuoteList />,
  //   path: '/apps/quote/list'
  // },
  {
    element: <QuotePreview />,
    path: '/apps/quote/preview/:id'
  },
  {
    element: <QuoteEdit />,
    path: '/apps/quote/edit/:id'
  },
  {
    element: <Rates />,
    path: '/apps/invoicing/rates'
  },
  {
    element: <InvoicingTInvList />,
    path: '/apps/invoicing/t/inv/list'
  },
  {
    element: <InvoicingTBkgList />,
    path: '/apps/invoicing/t/booking/list'
  },
  {
    element: <InvoicingChrgSumm />,
    path: '/apps/invoicing/t/chargesummary/list'
  },
  {
    element: <InvoicingChrgSummEdit />,
    path: '/apps/invoicing/t/chargesummary/edit/:id'
  },
  {
    element: <InvoicingChrgSummPreview />,
    path: '/apps/invoicing/t/chargesummary/preview/:id'
  },
  {
    element: <InvoicingTInvPreview />,
    path: '/apps/invoicing/t/inv/preview/:id'
  },
  {
    element: <InvoicingTInvEdit />,
    path: '/apps/invoicing/t/inv/edit/:id'
  },
  {
    element: <EcommerceShop />,
    path: '/apps/ecommerce/shop',
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    element: <EcommerceWishlist />,
    path: '/apps/ecommerce/wishlist',
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    path: '/apps/ecommerce/product-detail',
    element: <Navigate to='/apps/ecommerce/product-detail/apple-i-phone-11-64-gb-black-26' />,
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    path: '/apps/ecommerce/product-detail/:product',
    element: <EcommerceDetail />,
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    path: '/apps/ecommerce/checkout',
    element: <EcommerceCheckout />,
    meta: {
      className: 'ecommerce-application'
    }
  },
  {
    element: <UserList />,
    path: '/apps/user/list'
  },
  {
    path: '/apps/user/view',
    element: <Navigate to='/apps/user/view/1' />
  },
  {
    element: <UserView />,
    path: '/apps/user/view/:id'
  },
  {
    element: <DepartmentList />,
    path: '/apps/user/department'
  },
  {
    element: <CRMCompanyList />,
    path: '/apps/company/list'
  },
 
  {
    element: <CRMCompanyView />,
    path: '/apps/company/view/:id'
  },
  {
    element: <CustomerPortalList />,
    path: '/apps/crm/customer-portal/list'
  },
  {
    element: <CustomerPortalView />,
    path: '/apps/crm/customer-portal/view/:id'
  },
  {
    element: <CRMContactsList />,
    path: '/apps/crm/contacts/list'
  },
  {
    element: <Roles />,
    path: '/apps/roles'
  },
  {
    element: <Permissions />,
    path: '/apps/permissions'
  },
  {
    element: <TerminalIngateList />,
    path: '/apps/terminal/:terminal/:movementType'
  },
  {
    element: <ViewTerminalDetail />,
    path: '/apps/terminal/:terminal/:movementType/:id'
  },
  // {
  //   element: <TerminalIngate />,
  //   path: '/apps/terminal/inoutgate/:terminal'
  // },
  {
    element: <ReportDataSumm />,
    path: '/apps/reports/datasumm'
  },
  {
    element: <ReportStuffing />,
    path: '/apps/reports/:type'
  },
  {
    element: <ShipmentProgress />,
    path: '/apps/reports/shipment-progress'
  },
  {
    element: <SpecialReports />,
    path: '/apps/reports/special-reports'
  },
  {
    element: <SpecialReportsView />,
    path: '/apps/reports/special-reports/view/:customerCode'
  },
  {
    element: <InboundTracing />,
    path: '/apps/reports/inbound-tracing'
  },
  {
    element: <CleaningLog />,
    path: '/apps/reports/cleaning-log'
  },
  {
    element: <ReportStuffingPreview />,
    path: '/apps/reports/stuffing/preview/:oid/:bid'
  },
  {
    element: <ReportVGMPreview />,
    path: '/apps/reports/vgm/preview/:oid/:bid'
  },
  {
    element: <ReportFFReportPreview />,
    path: '/apps/reports/ff-booking/preview/:oid/:bid'
  },
  {
    element: <CleanlinessCertificate />,
    path: '/apps/reports/certificates'
  },
  {
    element: <CleanlinessPreview />,
    path: '/apps/reports/cleanliness/preview/:bid'
  },
  {
    element: <WeightPreview />,
    path: '/apps/reports/weight/preview/:transloadOrderNumber/:bookingNumber'
  },
  {
    element: <OriginPreview />,
    path: '/apps/reports/origin/preview/:transloadOrderNumber/:bookingNumber'
  },
  {
    element: <WoodPacking />,
    path: '/apps/reports/wood-packing/preview/:transloadOrderNumber/:bookingNumber'
  },
  {
    element: <NonWoodPacking />,
    path: '/apps/reports/non-wood-packing/preview/:transloadOrderNumber/:bookingNumber'
  },
  {
    element: <ReportRailcarPreview />,
    path: '/apps/reports/carrecon/preview/:oid/:bid'
  },
  {
    element: <StorageOut />,
    path: '/apps/reports/storage-out/preview/:transloadOrderNumber/:bookingNumber'
  },
  {
    element: <DemurrageIMDL />,
    path: '/apps/reports/demurrage-imdl/preview/:oid/:bid'
  },
  {
    element: <NovaTransloadingReport />,
    path: '/apps/reports/nova-transloading/:PONumber'
  },
  {
    element: <CtcIntactReport />,
    path: '/apps/reports/ctc-intactReport/:customerCode'
  },
  {
    element: <FreightForwardList />,
    path: '/apps/freight-forward-request/list'
  },
  {
    element: <SingleSignOnList />,
    path: '/apps/admin/singlesignon'
  },
  {
    path: '/apps/freight-forward-request/view',
    element: <Navigate to='/apps/freight-forward-request/view/1' />
  },
  {
    element: <FreightForwardView />,
    path: '/apps/freight-forward-request/view/:id'
  },
  {
    element: <OceanSchedule />,
    path: '/apps/freight-forward-request/schedule'
  },
  {
    path: '/apps/freight-forward-request/booking-confirmation-print/:id',
    element: <FreightForwardPrint />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/apps/freight-forward-request/departure-notice-print/:id',
    element: <FreightForwardDepartureNoticePrint />,
    meta: {
      layout: 'blank'
    }
  },
  {
    element: <FreightForwardPreview />,
    path: '/apps/freight-forward-request/booking-confirmation-preview/:ffNumber'
  },
  {
    element: <FreightForwardPreview />,
    path: '/apps/freight-forward-request/booking-confirmation-preview/:id/:rid'
  },
  {
    element: <FreightForwardDepartureNoticePreview />,
    path: '/apps/freight-forward-request/departure-notice-preview/:ffNumber'
  },
  {
    element: <FreightForwardDepartureNoticePreview />,
    path: '/apps/freight-forward-request/departure-notice-preview/:id/:rid'
  },
  {
    path: '/apps/freight-forward-request/preview',
    element: <Navigate to='/apps/freight-forward-request/preview/4987' />
  },
  {
    element: <ShipmentsList />,
    path: '/apps/freight-forward-request/shipments'
  },
  {
    element: <InboundRequestList />,
    path: '/apps/inbound-request/list'
  },
  {
    path: '/apps/inbound-request/view',
    element: <Navigate to='/apps/inbound-request/view/1' />
  },
  {
    element: <InboundRequestView />,
    path: '/apps/inbound-request/view/:id'
  },
  {
    element: <OutboundRequestList />,
    path: '/apps/outbound-request/list'
  },
  {
    element: <Dispatch />,
    path: '/apps/dispatch/list'
  },
  {
    element: <YardMove />,
    path: '/apps/yard-movement/list'
  },
  {
    element: <TodoListTransportation />,
    path: '/apps/to-do-list/list'
  },
  {
    element: <DriverList />,
    path: '/apps/transportation/driver/list'
  },
  {
    element: <DriverView />,
    path: '/apps/transportation/driver/view/:id'
  },
  {
    element: <DriverBlockList />,
    path: '/apps/transportation/driver/block-driver/list'
  },
  {
    element: <CRMLeadsList />,
    path: '/apps/crm/leads/list'
  },
  {
    element: <CRMLeadsView />,
    path: '/apps/crm/leads/view/:id'
  },
  {
    element: <CRMDealsList />,
    path: '/apps/crm/deals/list'
  },
  {
    element: <CRMDealsView />,
    path: '/apps/crm/deals/view/:id'
  },
  {
    element: <CRMDealsKanban />,
    path: '/apps/crm/deals/kanban'
  },
  {
    element: <CRMContactView />,
    path: '/apps/crm/contacts/view/:id'
  },
  {
    element: <HRMTimeSheetList />,
    path: '/apps/hrm/time-sheet/list'
  },
  {
    element: <HRMTimeSheetReportDateRangeList />,
    path: '/apps/hrm/time-sheet/report/date-range'
  },
  {
    element: <HRMTimeSheetReportPrint />,
    path: '/apps/hrm/time-sheet/print'
  },
  {
    element: <LeaveBoard />,
    path: '/apps/hrm/employees/leave-board'
  },
  {
    element: <HolidayCalendar />,
    path: '/apps/hrm/holiday-calendar'
  },
  {
    element: <UserSkills />,
    path: '/apps/hrm/skills/list'
  },
  {
    element: <HRMPayPeriodList />,
    path: '/apps/hrm/piece-work/list'
  },
  {
    element: <HRMPieceWorkReportDateRangeList />,
    path: '/apps/hrm/time-sheet/piece-work/report/:id'
  },
  {
    element: <HRMPieceWorkReportPrint />,
    path: '/apps/hrm/time-sheet/piece-work/print/:id'
  },
  {
    element: <VesselVoyage />,
    path: '/apps/vesselvoyage/schedule/list'
  },
  {
    element: <VesselTracker />,
    path: '/apps/vesselvoyage/tracker'
  },
  {
    element: <HrConfiguration />,
    path: '/apps/configuration/hr'
  },
  {
    element: <CompanyConfiguration />,
    path: '/apps/configuration/company'
  },
  {
    element: <QualityAssuranceConfiguration />,
    path: '/apps/configuration/quality-assurance'
  },
  {
    element: <CommodityConfiguration />,
    path: '/apps/configuration/commodity'
  },
  {
    element: <PackagingConfiguration />,
    path: '/apps/configuration/packaging'
  },
  {
    element: <TransloadOrderConfiguration />,
    path: '/apps/configuration/transload-order'
  },
  {
    element: <LoadingInstruction />,
    path: '/apps/transload-order/view/:id'
  },
  {
    element: <LoadingInstruction />,
    path: '/apps/transload-order/view/:id/:bookingId'
  },
  {
    element: <QualityIssueConfiguration />,
    path: '/apps/configuration/quality-issue'
  },
  {
    element: <VesselList />,
    path: '/apps/vesselvoyage/vessel/list'
  },
  {
    element: <VesselMind />,
    path: '/apps/vesselvoyage/vesselmind/list'
  },
  {
    element: <TerminalList />,
    path: '/apps/vesselvoyage/terminal/list'
  },
  {
    element: <OceanBookingList />,
    path: '/apps/ocean-booking/list'
  },
  {
    element: <OceanBookingView />,
    path: '/apps/ocean-booking/view/:id'
  },
  {
    element: <LandBookingList/>,
    path: '/apps/land-booking/list'
  },
  {
    element: <LandBookingView />,
    path: '/apps/land-booking/view/:id'
  },
  {
    element: <TransloadOrder />,
    path: '/apps/transload-order/list'
  },
  {
    element: <TemporaryStorageTransloadOrder />,
    path: '/apps/transload-order/temporary-storage/list'
  },
  {
    element: <SpecialTransloadMain />,
    path: '/apps/transload-order/special-transload/list'
  },
  {
    element: <ViewSpecialTransload />,
    path: '/apps/transload-order/special-transload/view/:id'
  },
  {
    element: <Instruction />,
    path: '/apps/transload-order/loading-instruction'
  },
  {
    element: <PackagingManagement />,
    path: '/apps/inventory/packaging-management'
  },
  {
    element: <PackagingData />,
    path: '/apps/inventory/packaging-management/:id'
  },
  {
    element: <DunnageManagement />,
    path: '/apps/inventory/dunnage-management'
  },
  // {
  //   element: <DunnageData />,
  //   path: '/apps/inventory/dunnage-management/:id'
  // },
  {
    element: <MaterialInventory />,
    path: '/apps/inventory/material-inventory'
  },
  {
    element: <CargoInventory />,
    path: '/apps/inventory/cargo-inventory'
  },
  {
    element: <EquipmentInventoryList />,
    path: '/apps/equipment/inventory/list/:terminal'
  },
  {
    element: <EquipmentStorageList />,
    path: '/apps/equipment/storage/list/:terminal'
  },
  {
    element: <EDIList />,
    path: '/apps/terminal/edi-records'
  },
  {
    element: <BlockList />,
    path: '/apps/terminal/block-list'
  }

]

export default AppRoutes

